<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>
        COBRA Administration
      </h1>
    </div>
    <div class="flex flex-col flex-1 my-4 sm:flex-row">
      <Button
        class="w-auto my-2 sm:mr-2"
        reg
        href="https://cobrapoint.benaissance.com/"
      >
        COBRA Login
      </Button>
      <Button
        class="w-auto my-2"
        reg
        href="https://cobrapoint.benaissance.com/Registration/Identify"
      >
        COBRA Registration
      </Button>
    </div>
    <div class="mx-auto prose-md md:prose-lg">
      <p>
        COBRA is a federal law appearing in three places: the Employee
        Retirement Income Security Act (ERISA); the Internal Revenue Code
        (Code); and the Public Health Service Act (PHSA).
      </p>
      <p>
        These laws allow qualifying employees who lose their health benefits
        through termination or a “qualifying event” to continue with the group
        health plan for a limited time. Coverage can be cancelled anytime by
        emailing the request to
        <a href="mailto:cobra@rmrbenefits.com" class="inline"
          >cobra@rmrbenefits.com</a
        >. This request can be submitted at any time of year but must be
        submitted by the end of the month for the following month as coverage
        cannot end part way through a month.
      </p>
    </div>

    <InfoDropdown title="What Plans Are Subject to COBRA?" :headerLvl="2">
      <p>
        Group Health Plans: COBRA applies to nearly all plans that provide
        medical care and are maintained by the employer. The term “medical care”
        has a broad definition in the law. It includes medical, dental, vision,
        and drug treatment coverage.
      </p>
      <p>Plans Subject To COBRA:</p>
      <ul>
        <li>plans for which an HMO provides the medical services;</li>
        <li>group insurance plans in which employees pay the premiums;</li>
        <li>treatment programs and clinics maintained by employers;</li>
        <li>self-insured medical reimbursement plans;</li>
        <li>employee assistance programs;</li>
        <li>health flexible spending accounts;</li>
        <li>health reimbursement arrangements (HRAs);</li>
        <li>discount programs; and</li>
        <li>wellness programs.</li>
      </ul>

      <p>
        Example: Health and Life Insurance. An employer covers all employees
        under a medical plan and group term life insurance. When an employee
        quits, he or she may elect to continue coverage under the medical plan.
        The employee has no right under COBRA to continue group term life
        insurance because it is not medical care. (Note, however, that some
        state laws give employees the right to continue group term life
        insurance).
      </p>
    </InfoDropdown>

    <InfoDropdown title="Important features of the plan" :headerLvl="2">
      <p>
        Certain plans that provide for long-term care services are not
        considered group health plans and are not subject to COBRA – even when
        they provide for benefits that include medical care.
      </p>
      <p>
        Some Voluntary Plans Are Subject to COBRA. Some employers offer their
        employees voluntary employee-pay-all medical insurance programs, in
        which the employers have minimal involvement (such as allowing the
        insurer to contact employees during working hours, collecting employee
        premiums and remitting them to the insurer, and similar activities).
        These arrangements can be subject to COBRA, and employers should get
        advice before encouraging or allowing them; otherwise, employers can be
        left with COBRA obligations and no corresponding insurance coverage.
      </p>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button,
    InfoDropdown
  }
};
</script>
